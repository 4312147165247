
const namespace = 'auth';

import { Component, Vue } from 'vue-property-decorator'

//utils
import { isEmpty } from '@/utils';

//Vuex
import { Action, Getter } from 'vuex-class';

//molds
import { Form, Input } from '@/components/wrappers/molds/Form';

//components
import PublicContent from '@/components/publicContent/PublicContent.vue'

//classes
import { Alert } from '@/services/notification'

//wrappers
import { WForm, WButton, WSubtitle, Box, Loader, SizedBox } from '@/components/wrappers';

@Component({
	components: {
		PublicContent,
		SizedBox,
		WForm,
		WButton,
		WSubtitle,
		Box, Loader,
	}
})

export default class Login extends Vue {

	@Action('auth', { namespace }) dispathLogin: any;
	@Action('logout', { namespace }) logout: any;
	@Getter('error', { namespace }) loginError!: any;

	alert = new Alert(this.$store)

	isLoading = false
	
	mounted(): void {
		this.logout()
	}

	dispatchForm(dataForm: Input[]): void {
		this.dispathLogin(dataForm).then(() => {
			if (this.loginError == false) {
				// this.alert.success("Cidadão reconhecido")
				this.$router.replace(this.route)
			} else if (this.loginError == 403) {
				this.alert.danger("Credeciais inválidas")
				this.isLoading = false
			} else if (this.loginError == 5000) {
				this.alert.danger("Desculpe ocorreu um erro: 5000")
				this.isLoading = false
			} else {
				this.alert.apiMessage("Erro Inesperado! :(", this.loginError, "danger")
				this.isLoading = false
			}			
		})
	}

	get route(): string {
		if(!isEmpty(`${this.$route.query.route}`)){
			return `${this.$route.query.route}`
		} return "/"
	}

	get form(): Form {
		return {
			flex: false,
			indent: true,
			grid: [
				[
					{
						fieldName: "name",
						label: "Nome",
						type: "text",
						maxlength: 100,
						placeholder: "nome",
						required: true,
						// value: "Cidadão Responsável"
					}
				],
				[
					{
						fieldName: "document",
						label: "CPF",
						type: "cpf",
						maxlength: 50,
						placeholder: "documento",
						required: true,
						// value:"00856807290"
					}
				],
				[
					{
						fieldName: "birthDate",
						label: "Data de Nascimento",
						type: "date",
						maxlength: 10,
						required: true,
						// value: "1992-01-28"
					}
				],
			]
		}
	}

	onRecovery(dataForm: Input[]): void {
		console.log("onRecovery");		
	}

	get formRecovery(): Form {
		return {
			flex: false,
			indent: true,
			grid: [
				[
					{
						fieldName: "username",
						label: "Email",
						type: "email",
						maxlength: 50,
						placeholder: "seu email",
						required: true,
						value: "oyrafael@gmail.com"
					}
				],
			]
		}
	}

	clickBtnRecovery(): void {
		this.$router.push("/?recovery=true")
		this.$forceUpdate()
	}

	clickBtnLogin(): void {
		this.$router.push("/?recovery=false")
	}

}
